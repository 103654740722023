import { Injectable } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { COLLECTIONS } from '@models/collections';
import { Season } from '@models/competitions';
import { Movement } from '@models/inscription';
import { Invoice } from '@models/invoice';
import { LicenseUserRate } from '@models/license';
import { MovementType } from '@models/masters';
import { MatchRequest } from '@models/requests';
import { Team } from '@models/teams';
import { User } from '@models/user';
import { combineLatest, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { ClubsService } from './club.service';
import { FirestoreService } from './firestore.service';
import { SeasonManagerService } from './season-manager.service';
import { SeasonsService } from './seasons.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ClubManagerService {
  public club$ = this.usersService.user$.pipe(
    switchMap(({ clubId }) => {
      return clubId ? this.clubService.get(clubId) : of(null);
    }),
    shareReplay(1),
  );

  public clubBalance$ = combineLatest([this.club$, this.seasonManagerService.season$]).pipe(
    switchMap(([club, season]) => {
      return club && season
        ? this.firestore.listDocuments<Movement>(
            COLLECTIONS.MOVEMENTS,
            where('clubId', '==', club.id),
            where('seasonYear', '==', season),
          )
        : of([]);
    }),
    map((movements) => {
      const sortedMovements = movements.sort((a, b) => {
        const dateA = a.date ? new Date(a.date).getTime() : new Date(a.createdAt || 0).getTime();
        const dateB = b.date ? new Date(b.date).getTime() : new Date(b.createdAt || 0).getTime();
        return dateB - dateA;
      });
      const expense = movements
        .filter((movement) => movement.movementType === MovementType.EXPENDITURE)
        .reduce((acc, movement) => acc + +movement.amount, 0);
      const income = movements
        .filter((movement) => movement.movementType === MovementType.INCOME)
        .reduce((acc, movement) => acc + +movement.amount, 0);

      return {
        movements: sortedMovements,
        expense,
        income,
        balance: income - expense,
      };
    }),
    shareReplay(1),
  );

  public myTeams$ = combineLatest([this.club$, this.seasonManagerService.season$, this.seasonsService.all$]).pipe(
    switchMap(([club, seasonYear, seasons]) =>
      this.firestore
        .listDocuments<Team>(
          COLLECTIONS.TEAMS,
          where('clubId', '==', club ? club.id : ''),
          where('seasonYear', '==', seasonYear),
        )
        .pipe(
          map((teams) =>
            teams.map((team) => ({
              ...team,
              season: team.seasonId ? seasons.find((season) => season.id === team.seasonId) : undefined,
            })),
          ),
          map((teams) => teams || []),
        ),
    ),
    shareReplay(1),
  );

  public myInvoices$ = combineLatest([this.club$, this.seasonManagerService.season$]).pipe(
    switchMap(([club, seasonYear]) =>
      this.firestore.listDocuments<Invoice>(
        COLLECTIONS.INVOICES,
        where('clubId', '==', club ? club.id : ''),
        where('seasonYear', '==', seasonYear),
        orderBy('createdAt', 'desc'),
      ),
    ),
    shareReplay(1),
  );

  public myPlayers$ = this.club$.pipe(
    switchMap((club) =>
      club ? this.firestore.listDocuments<User>(COLLECTIONS.USERS, where('clubId', '==', club.id)) : of([]),
    ),
    shareReplay(1),
  );

  public myClubPlayersWithLicenses$ = combineLatest([this.club$, this.seasonManagerService.season$]).pipe(
    switchMap(([club, seasonYear]) =>
      club
        ? this.firestore
            .listDocuments<Team>(
              COLLECTIONS.TEAMS,
              where('clubId', '==', club.id),
              where('seasonYear', '==', seasonYear),
            )
            .pipe(map((teams) => ({ teams, seasonYear })))
        : of({ teams: [], seasonYear }),
    ),
    switchMap(({ teams, seasonYear }) => {
      if (!teams.length) {
        return of([]);
      }

      const userIds = [...new Set(teams.flatMap((team) => [...(team.delegatesIds || []), ...(team.playersIds || [])]))];

      return combineLatest(
        userIds.map((userId) =>
          this.firestore.getDocument<User>(COLLECTIONS.USERS, userId).pipe(
            switchMap((user) =>
              this.firestore
                .listDocuments<LicenseUserRate>(
                  [COLLECTIONS.USERS, userId, COLLECTIONS.USERS_LICENSES].join('/'),
                  where('seasonYear', '==', seasonYear),
                )
                .pipe(
                  map((licenses) => ({
                    user,
                    licenses,
                  })),
                ),
            ),
          ),
        ),
      );
    }),
    shareReplay(1),
  );

  public myRequests$ = this.myTeams$.pipe(
    switchMap((teams) => {
      if (!teams || teams.length === 0) {
        return of([]);
      } else {
        const teamsIds = teams.map((team) => team.id);
        const chunkArray = (array: string[], size: number) => {
          const result = [];
          for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, size + i));
          }
          return result;
        };

        const teamChunks = chunkArray(teamsIds, 30);
        return combineLatest(
          teamChunks.map((teamChunk) =>
            this.firestore.listDocuments<MatchRequest>(
              COLLECTIONS.REQUESTS,
              where('toId', 'in', teamChunk),
              where('status', '==', 'PENDING'),
            ),
          ),
        ).pipe(map((results) => results.flat()));
      }
    }),
    shareReplay(1),
  );

  getTeam(teamId: string): Observable<Team & { season: Season }> {
    return combineLatest([this.firestore.getDocument<Team>(COLLECTIONS.TEAMS, teamId), this.seasonsService.all$]).pipe(
      map(([team, seasons]) => {
        const season = seasons.find((season) => season.id === team.seasonId);
        return {
          ...team,
          season: season ? season : ({} as Season),
        };
      }),
      shareReplay(1),
    );
  }

  constructor(
    private firestore: FirestoreService,
    private clubService: ClubsService,
    public seasonsService: SeasonsService,
    private usersService: UserService,
    private seasonManagerService: SeasonManagerService,
  ) {}
}
